import { mainAxios } from '@/plugins/axios';

class ProductService {
  async getProductsByUrl(url, order) {
    const orderField = `?order_field=desc_prod&order=${order}&`;
    const splitString = url.split('?');
    const completeUrl = `${splitString[0]}${orderField}${splitString[1]}`;
    const response = await mainAxios.get(`${completeUrl}`);
    return response.data;
  }
  async getProductCount() {
    const response = await mainAxios.get(`totalizadores/produtos`);
    return response.data;
  }
  async getListProducts(url) {
    const response = await mainAxios.get(url);
    return response.data;
  }
  async exportProducts(id, config) {
    return await mainAxios.get('exportar-produtos/' + id, config);
    return response;
  }
  async getPerPage(perPage, order) {
    const response = await mainAxios.get(
      `/produto/gateway?order_field=desc_prod&order=${order}&per_page=${perPage}`
    );
    return response.data;
  }
  async listNotRelations(search = '', limit = '') {
    const response = await mainAxios.get(
      `/produto/gateway/sem-relations?paginate=false&insumo=insumo&search=${search}&limit=${limit}`
    );
    return response.data;
  }
  async listNotRelationsNotInsumo(search = '', limit = '') {
    const response = await mainAxios.get(
      `/produto/gateway/sem-relations?paginate=false&search=${search}&limit=${limit}`
    );
    return response.data;
  }
  async sortProduct(type, item) {
    const response = await mainAxios.get(
      `/produto/gateway?order=${type}&order_field=${item}`
    );
    return response.data;
  }
  async searchProduct(term) {
    const response = await mainAxios.get(`/produto/gateway?search=${term}`);
    return response.data;
  }
  async removeProduct(id) {
    const response = await mainAxios.delete(`/produto/gateway/${id}`);
    return response.data;
  }
  async forceDelete(id) {
    const response = await mainAxios.delete(`/produto/gateway/${id}/force-delete`);
    return response.data;
  }
  async restoreProduct(id) {
    const response = await mainAxios.put(`/produto/gateway/${id}/restore`);
    return response.data;
  }
  async getProductById(id) {
    const response = await mainAxios.get(`/produto/gateway/${id}`);
    return response.data;
  }
  async deleteImage(productId) {
    const response = await mainAxios.delete(
      `/produto/gateway/${productId}/foto-produto`
    );
    return response.data;
  }
}

export default new ProductService();
